<template>
  <div class="page">
    <navbar index="2" />

    <!-- banner begain -->
    <banner>
      <img src="../assets/img/banner_customization.jpg" class="img-fluid" alt="定制化开发服务">
    </banner>
    <!-- banner end -->

    <div class="main-content">

      <!-- 技术流程 begain -->
      <div class="tech-box" id="tech">
        <section-title title="TECHNOLOGICAL PROCESS/技术流程" />
        <div class="tech-content">
          <div class="container-fluid tech-tab">
            <div class="tab-item" :class="techIndex == '0' ? 'active' : ''" @click.stop="switchTech(0)">
              <img src="../assets/img/tech_nav_1.jpg" alt="产品咨询及方案编写">
            </div>
            <div class="tab-item" :class="techIndex == '1' ? 'active' : ''" @click.stop="switchTech(1)">
              <img src="../assets/img/tech_nav_2.jpg" alt="产品设计">
            </div>
            <div class="tab-item" :class="techIndex == '2' ? 'active' : ''" @click.stop="switchTech(2)">
              <img src="../assets/img/tech_nav_3.jpg" alt="系统开发">
            </div>
            <div class="tab-item" :class="techIndex == '3' ? 'active' : ''" @click.stop="switchTech(3)">
              <img src="../assets/img/tech_nav_4.jpg" alt="测试交付">
            </div>
            <div class="tab-item" :class="techIndex == '4' ? 'active' : ''" @click.stop="switchTech(4)">
              <img src="../assets/img/tech_nav_5.jpg" alt="系统运维">
            </div>
          </div>
          <div class="container-fluid tab-content">
            <div class="content-item" v-for="(item, index) in techData" :key="index" :class="techIndex == index ? 'active' : ''">
              <div class="item-img">
                <img :src="item.img" class="img-fluid" :alt="item.title">
              </div>
              <div class="item-txt">
                <div class="title">{{item.title}}</div>
                <div class="desc">{{item.desc}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 技术流程 end -->

      <!-- 系统优势 begain -->
      <div class="advant-box" id="advant">
        <section-title title="SYSTEM ADVANTAGES/系统优势" />
        <div class="container-fluid advant-content">
          <div class="advant-item">
            <div class="row">
              <div class="col-4 offset-2 item-txt">
                <div>
                  <div class="title">整合各类数据，全面分析需求</div>
                  <div class="desc">诚派科技拥有多年软件开发经验。定制了标准化的产品开发流程，可以帮您专业全面分析需求。</div>
                </div>
              </div>
              <div class="col-4 item-img">
                <img src="../assets/img/advant_1.jpg" class="img-fluid" alt="">
              </div>
            </div>
            <div class="row">
              <div class="col-4 offset-2 item-img">
                <img src="../assets/img/advant_2.jpg" class="img-fluid" alt="">
              </div>
              <div class="col-4 item-txt">
                <div>
                  <div class="title">构建精准画像，沟通更近一步</div>
                  <div class="desc">诚派科技拥有多年软件开发经验。定制了标准化的产品开发流程，可以帮您专业全面分析需求。</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3 offset-2 item-txt">
                <div>
                  <div class="title">全流程标准化，轻松搞定各个环节</div>
                  <div class="desc">积攒了丰富的产品开发经验，设计流程标准化，轻松搞定各个设计环节。</div>
                </div>
              </div>
              <div class="col-7 item-img">
                <img src="../assets/img/advant_3.jpg" class="img-fluid" alt="">
              </div>
            </div>
            <div class="row">
              <div class="col-4 offset-2 item-img">
                <img src="../assets/img/advant_4.jpg" class="img-fluid" alt="">
              </div>
              <div class="col-3 offset-1 item-txt">
                <div>
                  <div class="title">快速开发，轻松节省各类成本</div>
                  <div class="desc">快速开发，复制成功模板，降低产品失败率，轻松节省各类成本。</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 系统优势 end -->
      
      <!-- 核心价值 begain -->
      <div id="value">
        <coreValue />
      </div>
      <!-- 核心价值 end -->

    </div>

    <foot />
  </div>
</template>

<script>
  import navbar from '@/components/navbar'
  import banner from '@/components/banner'
  import coreValue from '@/components/coreValue'
  import foot from '@/components/footer'
  import sectionTitle from '@/components/sectionTitle'
  import {locatePoint} from '@/mixin/locatePoint'
  export default {
    name: 'Custom',
    mixins: [locatePoint],
    data() {
      return {
        techIndex: 0,
        techData: [
          {
            img: require('../assets/img/tech_1.png'),
            title: '产品咨询及方案编写',
            desc: '诚派科技拥有一-支技术强大，经验丰富的开发团队。产品经理在了解了用户的初步需求后，会用相关的工具软件列出要开发的系统的大功能模块，每个大功能模块有哪些小功能模块，对于有些需求比较明确相关的界面时，在这一步里面可以初步定义好少量的界面与客户讨论清楚。在这系统分析员深入了解和分析需求，会整理出一份需求文档，这次的文档会清楚列出系统大致的大功能模块，大功能模块有哪些小功能模块，并且还列出相关的界面和界面功能。'
          },
          {
            img: require('../assets/img/tech_2.png'),
            title: '产品设计',
            desc: '在充分了解用户需求后对此进行定位，将信息注入到产品结构中去。对于不同页面有不同信息，以及页面与压面之间的信息跳转还有角色的使用权限分析都会在这个时候梳理清楚，这样就形成了产品原型，输出高保真模型。根据初期反馈完善交互,设计，用户体验设计，在确定了风格后，在对产品进行UI设计，最后输出效果图交由前端。'
          },
          {
            img: require('../assets/img/tech_3.png'),
            title: '系统开发',
            desc: '系统开发是从产品原型和产品UI设计图出发，形成软件的具体设计方案的过程，也就是说在需求分析阶段明确软件是“做什么”的基础上，解决软件“怎么做”的问题。结构化设计方将把软件设计分为概要设计和详细设计两个阶段。概要设计的主要任务是，通过分析需求规格说明对软件进行功能分解，确定软件的总体结构;详细设计阶段确定每个模块功能所需要的算法和数据结构，设计出每个模块的逻辑结构。软件设计阶段结束时的工作成果是软件设计说明书，它描述软件系统的组成模块结、模块间的调用关系，以及每个模块的输入、输出和详细的过程描述。'
          },
          {
            img: require('../assets/img/tech_3.png'),
            title: '测试交付',
            desc: '根据测试需求的测试通过情况，我们可以方便的查看到软件模块的质量分布情况,以及对应的开发质量，可以根据情况及时的进行优化或改进。所以在测试迭代中，我们都会要求对一级需求点的测试通过情况进行统计，确定需求点是否通过测试，哪些需求点通过测试，每条需求点的bug分布情况。'
          },
          {
            img: require('../assets/img/tech_5.png'),
            title: '系统运维',
            desc: '在产品上线后，我们会有专门的运维人员对网络、服务器、服务的生命周期各个阶段的运营与维护，在成本、稳定性、效率上达成一致可接受的状态。'
          }
        ]
      }
    },
    methods: {
      switchTech: function(index) {
        if (this.techIndex == index) return
        this.techIndex = index
      }
    },
    mounted () {
      this.$nextTick(() => {
        this.LP();
      })
    },
    components: {
      navbar,
      banner,
      coreValue,
      sectionTitle,
      foot
    }
  }
</script>

<style lang="scss" scoped>
  .tech-box {
    margin: 0 auto;
    padding: 60px 0;
    background-color: #fff;
    .tech-content {
      .tech-tab {
        margin-top: 100px;
        display: flex;
        max-width: 1300px;
        .tab-item {
          position: relative;
          flex: 0 0 20%;
          text-align: center;
          cursor: pointer;
          img {
            margin-bottom: 20px;
          }
          &::after {
            content: '';
            width: 0;
            height: 6px;
            background-color: #2663FF;
            position: absolute;
            left: 0;
            bottom: 0;
            transition: width .2s ease-in;
          }
          &:hover::after, &.active::after {
            width: 100%;
          }
        }
      }
      .tab-content {
        padding: 100px 120px;
        max-width: 1300px;
        box-shadow: 0 0 3px 1px rgba(0, 0, 0, .4);
        border-radius: 4px;
        .content-item {
          display: none;
          justify-content: space-between;
          &.active {
            display: flex;
          }
          .item-img {
            flex: 0 0 40%;
          }
          .item-txt {
            flex: 0 0 50%;
            .title {
              margin-bottom: 30px;
              line-height: 1.4;
              font-size: 24px;
              color: #666;
            }
            .desc {
              line-height: 1.4;
              font-size: 18px;
              color: #545454;
            }
          }
        }
      }
    }
  }
  .advant-box {
    margin: 0 auto;
    padding: 60px 0;
    .advant-content {
      margin-top: 70px;
      .advant-item {
        .row {
          margin-top: 60px;
          &:first-child {
            margin: 0;
          }
        }
        .item-txt {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .title {
            margin-bottom: 20px;
            line-height: 1;
            font-size: 30px;
            color: #333;
          }
          .desc {
            line-height: 1.4;
            font-size: 16px;
            color: #333;
          }
        }
      }
    }
  }
</style>